'use client';

import { cn } from '@finn/ui-utils';
import * as React from 'react';

export type TextareaProps =
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    label?: string;
    error?: string | boolean;
    testId?: string;
  };

const generateId = () => Math.random().toString(36).substring(7);

const HEIGHT_FOR_ROWS = {
  2: 'min-h-16',
  3: 'min-h-20',
  4: 'min-h-24',
  5: 'min-h-28',
};

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    { id: idProp, error, className, label, onChange, rows, testId, ...props },
    ref
  ) => {
    const id = React.useMemo(() => idProp || generateId(), [idProp]);
    const [localValue, setValue] = React.useState(
      String(props.value || props.defaultValue || '')
    );

    // Reset local value to support external form reset
    React.useEffect(() => {
      if (!props.value && !props.defaultValue && localValue) {
        setValue('');
      }
    }, [props.value, props.defaultValue, localValue]);

    // Keep local value in state for label animation
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
      onChange?.(event);
    };

    return (
      <div className="relative w-full" data-testid={testId}>
        <div
          className={cn(
            // layout
            'relative flex w-full flex-col bg-white pl-3 pt-4 text-black',
            // border
            'border-pearl box-border border border-solid',
            // focus styles
            'has-[:focus-visible]:outline has-[:focus-visible]:outline-2 has-[:focus-visible]:outline-black',
            // disabled styles
            'has-[:disabled]:bg-snow has-[:disabled]:text-iron has-[:disabled]:fill-iron has-[:disabled]:pointer-events-none has-[:disabled]:cursor-default',
            className,
            {
              'outline-red border-red has-[:focus-visible]:outline-red outline outline-1 has-[:focus-visible]:outline-1':
                error,
            }
          )}
        >
          <textarea
            id={id}
            ref={ref}
            className={cn(
              // layout
              'placeholder-iron peer flex h-full w-full px-0 pb-2 pt-4 focus:placeholder-opacity-100',
              // reset styles
              rows && rows < 6 ? HEIGHT_FOR_ROWS[rows] : HEIGHT_FOR_ROWS[3],
              'box-border border-none outline-none',
              'body-16-regular disabled:bg-snow disabled:text-iron',
              {
                'pt-0': !label,
              }
            )}
            onChange={handleChange}
            rows={rows}
            {...props}
            value={props.value === null ? '' : props.value}
          />
          {label ? (
            <label
              htmlFor={id}
              className={cn(
                // layout
                'body-12-regular absolute top-3',
                'peer-disabled:text-iron'
              )}
            >
              {label}
            </label>
          ) : null}
        </div>
        {typeof error === 'string' && error ? (
          <p className="body-12-regular text-red mt-3 text-left">{error}</p>
        ) : null}
      </div>
    );
  }
);

Textarea.displayName = 'Textarea';

export { Textarea };
