import PartnerVoucherBanner from '@finn/auto-ui/components/PartnerVoucherBanner';
import CheckoutContext from '@finn/auto-ui/contexts/Checkout';
import { useClosedUserGroup } from '@finn/auto-ui/contexts/ClosedUserGroup';
import { useLoyaltyAndSubscriptionTracker } from '@finn/auto-ui/modules/checkout/components/Pages/UnifiedCheckout/CheckoutAside/useLoyaltyAndSubscriptionTracker';
import { useUniversalRouter } from '@finn/auto-ui/modules/checkout/hooks/useCheckoutHelpers';
import { RecommendationSection } from '@finn/auto-ui/modules/recommendation/components/RecommendationSection';
import { useHandleModelCardSelection } from '@finn/auto-ui/utils/plp';
import { useDeals } from '@finn/platform-modules';
import {
  CombinedFilters,
  CombinedFiltersTags,
  DiscoveryConfigProvider,
  FilterKey,
  FilterProvider,
  Filters,
  FILTERS_RESPONSE_SWR_FALLBACK_KEY,
  ImplicitFilterProvider,
  ProductListing,
  QueryFilterProvider,
  useAvailableFiltersForState,
  useFiltersForProducts,
  useFilterValues,
  useIsOutOfStock,
  usePartnerDiscount,
  useProducts,
  useReplaceFilterValues,
} from '@finn/ua-vehicle';
import { useContext, useMemo, useRef } from 'react';
import { SWRConfig } from 'swr';

import {
  FiltersTracking,
  RestoreDefaultFilters,
} from '~/modules/products/filters';

import { ProductGridMetadata } from './ProductGridMetadata';

// TODO temporary extracted in separate component
// to provide access to context, will be removed after context removed
const PLPCore = ({ fallbackData, infinite_scroll, initialFilterValues }) => {
  const { closedUserGroupId } = useClosedUserGroup();
  const { cartInfo } = useContext(CheckoutContext);
  const partnerDiscount = usePartnerDiscount({
    closedUserGroupId,
    cartInfo,
  });
  const gridRef = useRef<HTMLDivElement | null>(null);
  useLoyaltyAndSubscriptionTracker('Product Listing');

  const { deals: subscriptions } = useDeals();
  const filtersForProductsToFetch = useFiltersForProducts({
    initialFilters: initialFilterValues,
    subscriptions,
  });
  const { filters, products, status, total, limit, loadNextPage, page } =
    useProducts({
      fallbackData,
      infinite: infinite_scroll,
      filters: filtersForProductsToFetch,
      gridRef,
    });
  const filterValues = useFilterValues();
  const availableFilters = useAvailableFiltersForState(filterValues);
  const isOutOfStock = useIsOutOfStock();
  const onModelCardSelection = useHandleModelCardSelection();
  const replaceFilterValues = useReplaceFilterValues();

  return (
    <div ref={gridRef} data-testid="product-listing">
      <RecommendationSection filters={filters} />
      <FiltersTracking total={total} />
      <ProductListing
        status={status}
        partnerDiscount={partnerDiscount}
        items={products}
        paginationType={infinite_scroll ? 'infinite' : 'pagination'}
        page={page}
        total={total}
        limit={limit}
        fetchNextPage={loadNextPage}
        filterValues={filterValues}
        availableFilters={availableFilters}
        isOutOfStock={isOutOfStock}
        onMessage={(type, ...args) => {
          if (type === 'model_card_selected') {
            onModelCardSelection(...args);
          }
          if (type === 'reset_filters') {
            replaceFilterValues({});
          }
        }}
      />
    </div>
  );
};

const ProductGrid = ({
  data: {
    title = '',
    show_filters = true,
    infinite_scroll = false,
    allAvailableFilters,
    initialFilterValues,
    vehicleOffset,
    vehicleLimit,
    totalVehiclesCount,
    vehicles,
  },
}: {
  data: ProductGridMetadata;
}) => {
  const router = useUniversalRouter();
  const { deals } = useDeals();

  const basePathname = router.asPath.split('?')[0];

  const fallbackData = useMemo(
    () => ({
      results: vehicles,
      offset: vehicleOffset ?? 0,
      total_results: totalVehiclesCount ?? 0,
    }),
    [totalVehiclesCount, vehicleOffset, vehicles]
  );

  const swrConfigValue = useMemo(() => {
    return {
      fallback: {
        [FILTERS_RESPONSE_SWR_FALLBACK_KEY]: allAvailableFilters,
      },
    };
  }, [allAvailableFilters]);

  const implicitFilters = useMemo(
    () => ({
      [FilterKey.HAS_DEALS]: initialFilterValues.has_deals,
      [FilterKey.PRODUCT_GROUP]: initialFilterValues.product_group,
      [FilterKey.HAS_RETENTION_DEALS]: initialFilterValues.has_retention_deals,
      [FilterKey.VIEW]: initialFilterValues.view,
    }),
    [initialFilterValues]
  );

  return (
    <SWRConfig value={swrConfigValue}>
      <QueryFilterProvider
        basePathname={basePathname}
        defaultFilterValues={initialFilterValues}
      >
        <ImplicitFilterProvider filters={implicitFilters}>
          <RestoreDefaultFilters defaultFilterValues={initialFilterValues} />
          <FilterProvider
            useQueryParamsOnly
            allAvailableFilters={allAvailableFilters}
            initialFilter={initialFilterValues as unknown as Filters}
            vehiclesOffset={vehicleOffset}
            vehiclesLimit={vehicleLimit}
            totalVehiclesCount={totalVehiclesCount}
            vehicles={vehicles}
          >
            <div className="container">
              <PartnerVoucherBanner className="mb-6" />
              {/* 
                  We use after:hidden to disable bottom cut for font and ensure that
                  font is not cutted at the bottom. We need to do it here because
                  of 0 margin between blocks. We also use after/before to cut font to 
                  emulate vetrical trim(design done in 2022 by designers)
                 */}
              {title && (
                <h2 className="mobile-t4-semibold md:web-t4-semibold after:!hidden">
                  {title}
                </h2>
              )}

              <div className="container md:flex md:justify-between">
                <DiscoveryConfigProvider
                  router={router}
                  deals={deals}
                  filterOverrides={{
                    power: {
                      hidden: true,
                    },
                  }}
                >
                  {show_filters ? (
                    <>
                      <CombinedFilters />
                      <div className="container">
                        <CombinedFiltersTags />
                        <PLPCore
                          fallbackData={fallbackData}
                          infinite_scroll={infinite_scroll}
                          initialFilterValues={initialFilterValues}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="container">
                      <PLPCore
                        fallbackData={fallbackData}
                        infinite_scroll={infinite_scroll}
                        initialFilterValues={initialFilterValues}
                      />
                    </div>
                  )}
                </DiscoveryConfigProvider>
              </div>
            </div>
          </FilterProvider>
        </ImplicitFilterProvider>
      </QueryFilterProvider>
    </SWRConfig>
  );
};

export default ProductGrid;
